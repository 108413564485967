import { GAWindow } from '../@types/GAWindow';

declare let window: GAWindow;

/**
 * A util function to create a Google Analytics event trigger.
 * @param eventName name of the event, e.g. 'click'
 * @param eventParams optional event parameters to specify label, category, etc.
 */
export const triggerGAEvent = (
    eventName: string,
    eventParams: Gtag.ControlParams | Gtag.CustomParams | Gtag.EventParams
) => {
    if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', eventName, eventParams);
    }
};

export const triggerPageView = () => {
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];

        setTimeout(() => {
            window.dataLayer.push({
                'event': 'pageview',
            });
        }, 50);
    }
};
