import { LinkProps } from '@reach/router';
import { graphql, Link } from 'gatsby';
import React, { AnchorHTMLAttributes, ComponentType, FC, useContext, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { fullWidthOnVertical } from '../../constants/css/fullWidthOnVertical';
import theme from '../../constants/theme';
import { CTAFragment } from '../../entities/operationResults';
import { triggerGAEvent } from '../../utils/triggerGAEvent';
import ArrowButton from '../atoms/ArrowButton';
import BodyText from '../atoms/BodyText';
import { WizardProps } from '../organisms/Wizard';
import ContentLink from '../atoms/ContentLink';
import cssCalcBetweenViewport from '../../utils/cssCalcBetweenViewport';
import loadable from '@loadable/component';
import { SiteContext } from '../../contexts/SiteContext';

export const callToActionFragment = graphql`
    fragment CTALinkFragment on Craft_CTALink {
        title
        ctaReason
        ctaSubtitle
        urlLink {
            entry {
                uri
            }
            target
            url
            text
        }
    }

    fragment CTAFragment on Craft_EntryInterface {
        __typename
        ...CTALinkFragment
        ...CTAContactFragment
        ...CTADownloadFragment
        ...CTANewsletterFragment
        ...CTAConsultFragment
        ...CTADemoFragment
        ...CTAVacancyFragment
        ...CTADigitalFragment
    }
`;

interface Props {
    data: CTAFragment;
    variant?: 'default' | 'hero' | 'caseStart';
    align?: 'left' | 'right';
    isVertical?: boolean;
    withMargin?: boolean;
    open?: boolean;
    noMessage?: boolean;
    sourceId: number;
}

export type CTAVariant = 'dark' | 'light';

export const getVariant = (type: CTAFragment['__typename']): CTAVariant => {
    switch (type) {
        case 'Craft_CTANewsletter':
            return 'dark';
        case 'Craft_CTAContact':
        case 'Craft_CTADownload':
        case 'Craft_CTALink':
        case 'Craft_CTAConsult':
        case 'Craft_CTADemo':
        case 'Craft_CTADigital':
        case 'Craft_CTAVacancy':
            return 'light';
        default:
            return 'dark';
    }
};

export const getWizardComponent = (type: CTAFragment['__typename']): ComponentType<WizardProps> | null => {
    switch (type) {
        case 'Craft_CTADownload':
            return loadable(() => import('../organisms/DownloadWizard'));
        case 'Craft_CTAContact':
            return loadable(() => import('../organisms/ContactWizard'));
        case 'Craft_CTANewsletter':
            return loadable(() => import('../organisms/NewsletterWizard'));
        case 'Craft_CTAConsult':
            return loadable(() => import('../organisms/ConsultWizard'));
        case 'Craft_CTADemo':
            return loadable(() => import('../organisms/DemoWizard'));
        case 'Craft_CTAVacancy':
            return loadable(() => import('../organisms/VacancyWizard'));
        case 'Craft_CTADigital':
            return loadable(() => import('../organisms/DigitalWizard'));
        default:
            return null;
    }
};

export const getWizardSlug = (type: CTAFragment['__typename']): string => {
    switch (type) {
        case 'Craft_CTADownload':
            return 'download';
        case 'Craft_CTAContact':
            return 'contact';
        case 'Craft_CTANewsletter':
            return 'nieuwsbrief';
        case 'Craft_CTAConsult':
            return 'adviesgesprek';
        case 'Craft_CTADemo':
            return 'demo';
        case 'Craft_CTAVacancy':
            return 'vacancy';
        case 'Craft_CTADigital':
            return 'digitaal-kennismaken';
        default:
            return '';
    }
};

const CTA: FC<Props> = ({
    data,
    isVertical,
    align = 'left',
    withMargin,
    variant = 'default',
    open = false,
    noMessage,
    sourceId,
}) => {
    const site = useContext(SiteContext);
    const [opened, setOpened] = useState(open);
    const Wizard = getWizardComponent(data.__typename);
    let href;
    let to;
    let target;
    useEffect(() => {
        const popHandler = (e: PopStateEvent) => {
            if (e.state) {
                if (e.state.opened === undefined || e.state.opened === false) {
                    setOpened(false);
                    e.preventDefault();
                }
            }
        };
        if (typeof window !== 'undefined') {
            window.addEventListener('popstate', popHandler);
        }
        return () => {
            window.removeEventListener('popstate', popHandler);
        };
    }, []);

    if (data.__typename === 'Craft_CTALink') {
        href = data.urlLink && !!data.urlLink.url ? `/${data.urlLink.url}/` : undefined;
        to =
            data.urlLink && data.urlLink.entry && data.urlLink.entry.uri
                ? site
                    ? `/${site}/${data.urlLink.entry.uri}/`
                    : `/${data.urlLink.entry.uri}/`
                : undefined;
        target = (data.urlLink && data.urlLink.target) || undefined;
    }

    switch (data.__typename) {
        case 'Craft_CTALink':
        case 'Craft_CTAContact':
        case 'Craft_CTADownload':
        case 'Craft_CTANewsletter':
        case 'Craft_CTAConsult':
        case 'Craft_CTADemo':
        case 'Craft_CTADigital':
        case 'Craft_CTAVacancy':
            break;
        default:
            return null;
    }

    const { ctaReason, ctaSubtitle, title, __typename } = data;
    const reason: string | null = ctaReason;

    return (
        <>
            <CTAContainer
                variant={variant}
                opening={opened}
                isVertical={isVertical}
                as={to ? Link : href ? 'a' : undefined}
                to={to}
                href={href}
                target={target}
                type={data.__typename}
                className="cta"
                onClick={() => {
                    // TODO: Discuss whether this is still necessary with RDB
                    triggerGAEvent('click', {
                        event_category: data.__typename.replace('Craft_', ''),
                        event_label: title,
                    });
                    if (data.__typename !== 'Craft_CTALink') {
                        setOpened(true);
                        window.history.pushState({ opened: true }, '', `./${getWizardSlug(data.__typename)}/`);
                    }
                }}
                align={align}
                withMargin={withMargin}
                open={open}
            >
                <BodyText variant={'huge'}>{reason}</BodyText>
                {ctaSubtitle && (
                    <StyledLink>
                        <ArrowButton />
                        {__typename !== 'Craft_CTALink' ? (
                            <BodyText variant={'small'}>
                                <strong>{ctaSubtitle}</strong>
                            </BodyText>
                        ) : (
                            to && (
                                <CTALink to={to}>
                                    <BodyText variant={'small'}>
                                        <strong>{ctaSubtitle}</strong>
                                    </BodyText>
                                </CTALink>
                            )
                        )}
                    </StyledLink>
                )}
            </CTAContainer>
            {opened && Wizard && (
                <Wizard
                    showPopover={opened}
                    setShowPopover={setOpened}
                    data={data}
                    variant={getVariant(data.__typename)}
                    withDelay
                    modal={!open}
                    noMessage={noMessage}
                    sourceId={sourceId}
                />
            )}
        </>
    );
};

export default CTA;

const StyledLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    > p {
        margin: 0;
        margin-left: 1rem;
    }
`;

const CTALink = styled(ContentLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    color: ${theme.colors.black};
    border-bottom: 0;
    > p {
        margin: 0;
        margin-left: 1rem;
    }
`;

const circleFill = keyframes`
    0% {
        transform: scale(0) translateZ(0px);
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    70% {
        transform: scale(2) translateZ(0px);
        opacity: 1;
    }
`;

interface CTAContainerProps extends Partial<LinkProps<{}>>, AnchorHTMLAttributes<HTMLAnchorElement> {
    type: CTAFragment['__typename'];
    align: 'left' | 'right';
    isVertical?: boolean;
    opening: boolean;
    withMargin?: boolean;
    variant: 'default' | 'hero' | 'caseStart';
    open: boolean;
}

export const CTAContainer = styled.aside<CTAContainerProps>`
    text-decoration: none;
    display: ${({ open }) => (open ? 'none' : 'flex')};
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    padding: 3.2rem 6rem;
    cursor: pointer;
    background: ${({ type }) => (getVariant(type) === 'light' ? theme.colors.gorse : theme.colors.royalBlue)};
    color: ${({ type }) => (getVariant(type) === 'light' ? theme.colors.black : theme.colors.white)};
    box-sizing: border-box;
    bottom: 0;
    z-index: 2;
    box-shadow: 7px 1px 96px rgba(0, 0, 0, 0.08);
    height: revert;
    width: ${cssCalcBetweenViewport(360, 560)};

    > p {
        margin: 0;
    }

    @media screen and (${theme.mediaQueries.horizontal}) {
        ${({ isVertical, align, variant }) =>
            isVertical
                ? `
                margin: 3rem 0;
            `
                : css`
                      position: absolute;
                      left: ${align === 'left' ? (variant === 'hero' ? '80rem' : '-8rem') : 'auto'};
                      right: ${align === 'right' ? (variant === 'hero' ? '100rem' : '-8rem') : 'auto'};
                  `}

        max-width: 60rem;

        // circle fill animation
        &:before {
            position: absolute;
            bottom: -50vmax;
            left: calc(50% + -50vmax);
            height: 100vmax;
            width: 100vmax;
            pointer-events: none;
            border-radius: 50%;
            opacity: 0;
            content: '';
            display: block;
            background: ${({ type }) => (getVariant(type) === 'light' ? theme.colors.gorse : theme.colors.royalBlue)};
            animation-name: ${({ opening }) => (opening ? circleFill : 'none')};
            animation-duration: 0.5s;
            animation-timing-function: ease-in;
        }
    }

    ${({ isVertical }) =>
        isVertical
            ? `
        @media (${theme.mediaQueries.vertical}) {
            margin: 3rem -2.5rem 5rem;
        }`
            : fullWidthOnVertical};

    @media (${theme.mediaQueries.vertical}) {
        ${({ withMargin, variant }) =>
            variant === 'caseStart' ? `margin-top: -5rem;` : withMargin && `margin-top: 5rem;`}
        padding: 3rem 3rem !important;
    }

    @media (${theme.mediaQueries.horizontal}) {
        ${({ isVertical }) =>
            !isVertical &&
            `
            position: absolute;
            left: ${({ align, variant }: CTAContainerProps) =>
                align === 'left' ? (variant === 'hero' ? '80rem' : '-8rem') : 'auto'};
            right: ${({ align, variant }: CTAContainerProps) =>
                align === 'right' ? (variant === 'hero' ? '100rem' : '-8rem') : 'auto'};
        `}

        max-width: 60rem;
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        max-width: 48rem;
        padding: 2.4rem 5.2rem;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        padding: 2.4rem 4rem;
    }

    @media (${theme.mediaQueries.vertical}) {
        position: initial;
        left: 0;
        right: 0;
        max-width: none;
    }
`;
