import React, { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
    variant?: 'light' | 'dark';
}

export default ({ variant = 'dark', ...rest }: Props) => (
    <svg
        style={{ filter: { light: 'invert(1)', dark: 'none' }[variant] }}
        {...rest}
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        version="1.1"
    >
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="CTA-box-small-02-alt--/-checklist" transform="translate(-60.000000, -136.000000)">
                <g id="Group-2" transform="translate(60.000000, 136.000000)">
                    <g id="ATOM-/-Go-button-blk">
                        <g id="Group-2">
                            <circle id="Oval" fill="#000000" fillRule="nonzero" cx="20" cy="20" r="20" />
                            <g
                                id="Group"
                                transform="translate(13.333333, 14.166667)"
                                fillRule="nonzero"
                                stroke="#FFFFFF"
                                strokeWidth="2"
                            >
                                <polyline
                                    id="Path-2"
                                    points="7.08333333 11.2083333 12.4583333 5.83333333 7.08333333 0.458333333"
                                />
                                <path d="M12.4583333,5.83333333 L0.416666667,5.83333333" id="Path-3" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
